import { render, staticRenderFns } from "./DownloadAPP.vue?vue&type=template&id=53224d9e&scoped=true&"
import script from "./DownloadAPP.vue?vue&type=script&lang=js&"
export * from "./DownloadAPP.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53224d9e",
  null
  
)

export default component.exports