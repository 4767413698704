<template>
  <Loading v-if="isLoading" />
  <div v-else>
    <section class="section-top progress">
      <h1 class="section-top-title">服務條款</h1>
    </section>
    <section class="section-terms section">
      <div class="section-content">
        <div v-if="serviceTerm" v-html="md.render(serviceTerm)"></div>
      </div>
    </section>
  </div>
</template>
<script>
import policyAPI from "@/apis/policy";
import Loading from "@/components/Loading.vue";
import markdownit from "markdown-it";

export default {
  name: "Service",
  components: {
    Loading,
  },
  data() {
    return {
      md: null,
      serviceTerm: "",
      isLoading: true,
    };
  },
  created() {
    this.md = markdownit();
    this.getPolicy();
  },
  beforeRouteUpdate() {
    this.getPolicy();
  },
  methods: {
    async getPolicy() {
      try {
        this.isLoading = true;
        let { data, status } = await policyAPI.policy.service();
        if (status !== 200) {
          throw new Error(status);
        }
        let { term } = data[0];
        this.serviceTerm = term;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.error(error.message);
      }
    },
  },
};
</script>
