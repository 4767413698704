import { apiHelper } from "../utils/helpers";

export default {
  policy: {
    service() {
      return apiHelper.get(`/service/serviceterm`);
    },
    privacy() {
      return apiHelper.get(`/service/privacy`);
    },
    UPointInstructions() {
      return apiHelper.get(`/service/u-point-instructions`);
    },
    autoChargeNote() {
      return apiHelper.get(`/service/autocharge-note`);
    },
  },
};
