var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"nav"},[_c('ul',{staticClass:"nav-list space-around"},[_c('li',{staticClass:"nav-list__item"},[_c('router-link',{staticClass:"router-link",attrs:{"to":{
          name: 'PointTransactions',
          query: {
            cardNo: this.$route.query.cardNo,
            phoneNumber: this.$route.query.phoneNumber,
            page: 1,
            pageSize: 500,
            startDate: _vm.startDate,
          },
        },"active-class":"active"}},[_vm._v(" 使用記錄")])],1),_c('li',{staticClass:"nav-list__item"},[_c('router-link',{staticClass:"router-link",attrs:{"to":{
          name: 'EarnedPoint',
          query: {
            cardNo: this.$route.query.cardNo,
            phoneNumber: this.$route.query.phoneNumber,
            page: 1,
            pageSize: 500,
            startDate: _vm.earnedPointsStartDate,
          },
        },"active-class":"active"}},[_vm._v("點數贈送")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }